<template>
  <div class="custom-container">
    <el-row v-if="!$route.query.noReturn">
      <el-page-header @back="goBack" content="创建委托单"></el-page-header>
    </el-row>
    <el-row>
      <div class="order-content">
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <h4>基本信息</h4>
          <el-row :gutter="24" class="mt20">
            <el-col :span="6">
              <el-form-item label="订单编号" prop="piNumber" label-width="90px">
                <el-input
                  v-model="form.piNumber"
                  placeholder="请输入"
                  clearable
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="计划单号" label-width="90px" required="">
                <el-input
                  v-model="form.planNumber"
                  placeholder="请输入"
                  clearable
                  disabled
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="运输方式"
                prop="shippingMethodId"
                label-width="90px"
              >
                <el-select
                  v-model="form.shippingMethodId"
                  placeholder="请选择"
                  clearable
                  @change="(val) => onShippingMethodChange(val)"
                >
                  <el-option
                    v-for="item in transpotwayList"
                    :key="item.freightSettingId"
                    :label="item.transportWayCn"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="贸易条款" prop="tradeId" label-width="90px">
                <el-select
                  v-model="form.tradeId"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in trades"
                    :key="item.amountId"
                    :label="item.amountName"
                    :value="item.amountId"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt20">
            <el-col :span="6">
              <el-form-item
                label="发货地点"
                prop="portShipment"
                label-width="90px"
              >
                <el-input
                  v-model="form.portShipment"
                  placeholder="请输入"
                  maxlength="50"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <!-- 单证员不可编辑 -->
              <el-form-item label="单证员" prop="clerkName" label-width="90px">
                <el-input
                  placeholder=""
                  disabled
                  v-model="form.clerkName"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6" style="display: flex; align-items: center">
              <el-form-item
                label="客户交期"
                prop="deliveryDate"
                label-width="90px"
                style="flex: 1"
              >
                <el-date-picker
                  v-model="form.deliveryDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions"
                ></el-date-picker>
              </el-form-item>
              <!-- 是否显示客户交期后面的Firm 交期  1=显示 0=不显示 -->
              <el-tag
                v-if="detailInfo.firmFlag == 1"
                size="mini"
                type="warning"
                effect="dark"
                class="ml10"
              >
                Firm 交期
              </el-tag>
            </el-col>

            <el-col :span="6" style="display: flex; align-items: center">
              <el-form-item
                label="装运日期"
                prop="shippingDate"
                label-width="90px"
                clearable
                :rules="[
                  {
                    required: true,
                    message: '装运日期必填',
                    trigger: ['change'],
                  },
                ]"
              >
                <el-date-picker
                  v-model="form.shippingDate"
                  type="date"
                  placeholder="选择日期"
                  value-format="yyyy-MM-dd"
                ></el-date-picker>
              </el-form-item>
              <el-button
                type="primary"
                size="mini"
                class="ml10"
                @click="handleSetShippingDate(1)"
              >
                明天
              </el-button>
              <el-button size="mini" @click="handleSetShippingDate(2)">
                后天
              </el-button>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt20">
            <!-- 国内物流 -->
            <el-col :span="6">
              <el-form-item
                label="国内物流"
                prop="domesticLogistics"
                label-width="90px"
              >
                <el-select
                  v-model="form.domesticLogistics"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in domesticLogisticsList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <!-- 快递单号 -->
            <el-col :span="6" v-if="form.domesticLogistics === 0">
              <el-form-item
                label="快递单号"
                prop="trackingNo"
                label-width="90px"
              >
                <el-input
                  v-model="form.trackingNo"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <!-- 垫付运费 -->
            <el-col :span="6">
              <el-form-item
                label="垫付运费"
                prop="advanceFreight"
                label-width="90px"
              >
                <el-select
                  v-model="form.advanceFreight"
                  placeholder="请选择"
                  clearable
                >
                  <el-option
                    v-for="item in advanceFreightList"
                    :key="item.code"
                    :label="item.name"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="客户名称"
                prop="customerName"
                label-width="90px"
              >
                <el-input
                  placeholder="请输入"
                  disabled
                  v-model="form.customerName"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" class="mt20">
            <el-col :span="6">
              <el-form-item
                label="客户代表"
                label-width="90px"
                prop="businessName"
              >
                <el-input
                  disabled
                  v-model="form.businessName"
                  @focus="showUserDialog"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item
                label="创建人"
                label-width="90px"
                prop="creatorName"
              >
                <el-input v-model="form.creatorName" disabled></el-input>
              </el-form-item>
            </el-col>
            <template
              v-if="
                canSelectAutoEmail &&
                form.shippingMethodId &&
                ingoreShippingMethodList.includes(
                  form.shippingMethod.toUpperCase()
                )
              "
            >
              <el-col :span="12">
                <el-form-item label-width="28px">
                  自动发送物流动态邮件,
                  <span class="page-link text-left" @click="handleView">
                    点击查看示例
                  </span>
                  <ErpMouseoverTip effect="dark" placement="bottom">
                    <div>
                      <p class="info-text">
                        1. 仅支持部分运输方式，并以实际出运时的运输方式为准；
                      </p>
                      <p class="info-text">
                        2. 若手动发送了发货邮件，系统将不再自动发送发货邮件；
                      </p>
                    </div>
                  </ErpMouseoverTip>
                </el-form-item>
              </el-col>
            </template>

            <el-col :span="24" class="mt20">
              <el-form-item label="备注" label-width="90px">
                <el-input
                  type="textarea"
                  v-model="form.remarks"
                  maxlength="500"
                  placeholder="请输入内容，字数500字以内"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <h4>交货方式</h4> -->
          <el-row :gutter="24" class="mt20"></el-row>
          <h4></h4>
          <div class="c-table-wrap">
            <table class="custom-table" :key="tableKey">
              <thead>
                <tr>
                  <th>
                    <span>
                      <!-- <i>*</i> -->
                      国家
                    </span>
                  </th>
                  <th>
                    <span>
                      <!-- <i>*</i> -->
                      收货地址
                    </span>
                  </th>
                  <th>
                    <span>
                      <!-- <i>*</i> -->
                      收货人
                    </span>
                  </th>
                  <th>
                    <span>
                      <!-- <i>*</i> -->
                      收货公司
                    </span>
                  </th>
                  <th>
                    <span>
                      <!-- <i>*</i> -->
                      联系电话
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {{ form.country }}
                  </td>
                  <td>
                    {{ form.destination }}
                  </td>
                  <td>
                    <!-- <el-form-item prop="receivingPerson">
                      <el-input
                        v-model="form.receivingPerson"
                        placeholder="请输入"
                        disabled
                      ></el-input>
                    </el-form-item> -->
                    {{ form.receivingPerson }}
                  </td>
                  <td>
                    <!-- <el-form-item prop="receivingCompany">
                      <el-input
                        v-model="form.receivingCompany"
                        placeholder="请输入"
                        disabled
                      ></el-input>
                    </el-form-item> -->
                    {{ form.receivingCompany }}
                  </td>
                  <td>
                    <!-- <el-form-item prop="receivingPhone">
                      <el-input
                        v-model="form.receivingPhone"
                        placeholder="请输入"
                        disabled
                      ></el-input>
                    </el-form-item> -->
                    {{ form.receivingPhone }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h4 style="display: inline-block">出运产品</h4>
          <div class="c-table-wrap">
            <table class="custom-table" :key="tableKey">
              <thead>
                <tr>
                  <th width="80" class="head-check">
                    <el-checkbox
                      v-model="allSelect"
                      @change="thSelect"
                    ></el-checkbox>
                  </th>
                  <th width="120">产品图片</th>
                  <th width="120">SKU ID</th>
                  <th style="min-width: 200px">中文报关名/英文报关名</th>
                  <th style="min-width: 180px">产品规格</th>
                  <th width="120">产品材质</th>
                  <th width="120">订单数量</th>
                  <th width="120">可用库存</th>
                  <th style="min-width: 200px">出库仓库</th>
                  <th width="120">
                    <span><i>*</i></span>
                    出运数量
                  </th>
                  <!-- v1.6.1入库批次 -->
                  <th width="160" style="min-width: 160px">
                    入库批次（数量）
                    <el-tooltip
                      class="item"
                      effect="dark"
                      placement="top"
                      trigger="click"
                    >
                      <div slot="content">
                        <p>
                          指当前产品的从哪个入库批次中发起出库，以及从当前入库批次中出的数量；
                        </p>
                        <br />
                        <p>若产品已提前出库，则入库批次无需设置；</p>
                      </div>
                      <i class="iconfont el-icon-question c_pointer"></i>
                    </el-tooltip>
                  </th>
                  <th width="100">买方</th>
                  <th width="100">
                    <span><i>*</i></span>
                    是否退税
                  </th>
                  <th width="120">
                    <span><i>*</i></span>
                    装箱尺寸
                  </th>
                  <th width="120">
                    <span><i>*</i></span>
                    总箱数
                  </th>
                  <th width="120">
                    <span><i>*</i></span>
                    总重量
                    <!-- (KGS) -->
                  </th>
                  <th width="120">
                    <span><i>*</i></span>
                    总体积
                    <!-- (CBM) -->
                  </th>
                  <th width="120">申报单价</th>
                  <th width="100">
                    <span><i>*</i></span>
                    唛头
                  </th>
                  <th width="80">操作</th>
                </tr>
              </thead>
              <tbody
                v-if="form.productArranges && form.productArranges.length > 0"
              >
                <tr v-for="(item, index) in form.productArranges" :key="index">
                  <td>
                    <el-checkbox
                      v-model="item.selected"
                      @change="tdSelect"
                    ></el-checkbox>
                  </td>
                  <!-- 产品图片 -->
                  <td style="position: relative">
                    <div style="width: 60px" class="product-img-box">
                      <erp-image
                        :imgs="
                          item.productImg ||
                          item.productSpecImg ||
                          item.productMainImg
                        "
                      ></erp-image>
                      <el-tag
                        size="mini"
                        class="el-tag-other"
                        v-if="item.sampleFlag"
                      >
                        {{ $t('AddOrder.PrenatalSample') }}
                      </el-tag>
                    </div>
                    <popoverShip
                      :itemTitle="
                        item.mainFlag == 1 && item.splitFlag == 1
                          ? '主'
                          : item.mainFlag == 0
                          ? '子'
                          : ''
                      "
                      :tableData="
                        item.mainFlag == 1 && item.splitFlag == 1
                          ? item.arrangeChildProducts
                          : item.mainFlag == 0
                          ? item.parentProduct
                          : []
                      "
                      triggerType="click"
                    ></popoverShip>
                  </td>
                  <!-- SKUID -->
                  <td class="page-link">
                    <span @click="showProDetail(item)" class="page-link">
                      {{ item.sku }}
                    </span>
                  </td>
                  <!-- 中文品名/英文品名 -->
                  <td>
                    {{ item.declareCn || item.productCn }}/{{
                      item.declareEn || item.productEn
                    }}
                  </td>
                  <td>
                    <p class="text-left">
                      英文规格：{{ item.productSpecs || '--' }}
                    </p>
                    <p class="text-left">
                      中文规格：{{ item.productSpecsCn || '--' }}
                    </p>
                  </td>

                  <!-- 产品材质 -->
                  <td>
                    {{ item.productMaterial ? item.productMaterial : '--' }}
                  </td>
                  <!-- 订单数量 -->
                  <td>{{ item.totalNum || '--' }}</td>
                  <!-- 待安排数量 -->
                  <!-- <td>{{ item.unShippedNum || '--' }}</td> -->
                  <!-- 可用库存 -->
                  <td>
                    <template
                      v-if="
                        item.shippingPlanOrderProductStockVOS &&
                        item.shippingPlanOrderProductStockVOS.length > 0
                      "
                    >
                      <p
                        v-for="(
                          i, index
                        ) in item.shippingPlanOrderProductStockVOS"
                        :key="index"
                      >
                        <span>{{ i.storageName }}:</span>
                        <span>{{ i.stockNum }}</span>
                      </p>
                    </template>
                    <span v-else>--</span>
                  </td>
                  <!-- 出库仓库 -->
                  <td>
                    <!-- 没有出库单 -->
                    <div v-if="item.alreadyStockIn !== '1'">
                      <template>
                        <div
                          v-if="
                            item.shippingPlanOrderProductStockVOS &&
                            item.shippingPlanOrderProductStockVOS.length > 0
                          "
                          style="display: flex; align-items: center"
                        >
                          <el-form-item>
                            <el-select
                              maxlength="10"
                              v-model="form.productArranges[index].warehouseId"
                              placeholder="请选择"
                              clearable
                              :disabled="
                                item.stockDisable ||
                                [1, 2].includes(
                                  form.productArranges[index]
                                    .hasNotCompleteInStock
                                )
                              "
                              @change="(val) => whChange(index, val)"
                              @visible-change="
                                (bool) => whVisibleChange(bool, index)
                              "
                            >
                              <el-option
                                v-for="subitem in item.shippingPlanOrderProductStockVOS"
                                :key="subitem.storageId"
                                :label="subitem.storageName"
                                :value="subitem.storageId"
                              />
                            </el-select>
                          </el-form-item>
                          <ErpMouseoverTip
                            effect="dark"
                            placement="top"
                            v-if="
                              [1, 2].includes(
                                form.productArranges[index]
                                  .hasNotCompleteInStock
                              )
                            "
                          >
                            <div>
                              <p
                                class="info-text"
                                v-if="
                                  form.productArranges[index]
                                    .hasNotCompleteInStock === 1
                                "
                              >
                                委外加工单未加工入库，请等待加工入库后进行此操作
                              </p>
                              <p class="info-text" v-else>
                                存在未确认入库的退货入库单，请确认入库后再出运
                              </p>
                            </div>
                          </ErpMouseoverTip>
                        </div>
                      </template>
                    </div>
                    <div v-else>
                      <p
                        v-for="(
                          stock, index
                        ) in item.shippingPlanOrderProductArrangeStockVOS"
                        :key="index"
                      >
                        {{ stock.warehouseName }}
                      </p>
                    </div>
                  </td>

                  <!-- 出运数量 -->
                  <td>
                    <el-form-item
                      :prop="'productArranges.' + index + '.shippedNum'"
                      :rules="[
                        {
                          required: true,
                          message: '出运数量必填',
                          trigger: 'blur',
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (value <= 0) {
                              callback(new error('出运数量为正整数'))
                            } else {
                              callback()
                            }
                          },
                          message: '出运数量为正整数',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        :controls="false"
                        v-model="form.productArranges[index].shippedNum"
                        placeholder="请输入"
                        controls-position="right"
                        disabled
                        :precision="0"
                        @change="(val) => shippedNumChange(index, val)"
                        @focus="shippedNumfocus(index)"
                      ></el-input-number>
                    </el-form-item>
                  </td>
                  <!-- 入库批次 -->
                  <td>
                    <LotDetail
                      :lotList="item.shippingOrderProductLotDTOList"
                      :isChoose="item.isChoose"
                      @lot-sel="selLot"
                      :index="index"
                      :key="index + tableKey"
                      :ref="index + 'locDetail'"
                    />
                  </td>
                  <!-- 买方 -->
                  <td>{{ item.purchaser || '--' }}</td>
                  <!-- 是否退税 -->
                  <td>
                    <el-form-item
                      class="selectFlex"
                      :prop="'productArranges.' + index + '.drawback'"
                      :rules="[
                        {
                          required: !item.drawbackDisabled,
                          message: '是否退税必填',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-select
                        v-model="item.drawback"
                        placeholder="请选择"
                        @change="(val) => changeDrawback(val, index, 1)"
                        :disabled="item.drawbackDisabled"
                      >
                        <el-option
                          v-for="item in drawbacks"
                          :key="item.code"
                          :label="item.name"
                          :value="item.code"
                        />
                      </el-select>
                      <ErpMouseoverTip
                        v-if="item.fromSale == 1 && item.showTip"
                        effect="dark"
                      >
                        你已修改此选项，且与采购订单上的「是否退税」选项不一致，请仔细核对
                      </ErpMouseoverTip>
                    </el-form-item>
                  </td>
                  <!-- 装箱尺寸 -->
                  <td
                    @mousemove="toggleEdit(index, true)"
                    @mouseleave="toggleEdit(index, false)"
                    width="170px"
                  >
                    <PackingSize
                      :ref="index + 'packingSize'"
                      :packingSizes="item.orderProductBoxDTOS"
                      @set-sizes="(val) => setSizes(index, val)"
                      :key="index + tableKey"
                    />
                    <p
                      v-if="
                        showRequired && item.orderProductBoxDTOS.length === 0
                      "
                      style="color: red"
                    >
                      请选择
                    </p>
                  </td>
                  <!-- 总箱数 -->
                  <td>
                    <el-form-item
                      :prop="'productArranges.' + index + '.caseNum'"
                      :rules="[
                        {
                          required: true,
                          message: '总箱数必填',
                          trigger: 'blur',
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (value < 0) {
                              callback(new error('总箱数为正整数'))
                            } else {
                              callback()
                            }
                          },
                          message: '总箱数为正整数',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="form.productArranges[index].caseNum"
                        placeholder="请输入"
                        controls-position="right"
                        :controls="false"
                        :precision="0"
                      ></el-input-number>
                    </el-form-item>
                  </td>
                  <!-- 总重量 -->
                  <td>
                    <el-form-item
                      :prop="'productArranges.' + index + '.grossWeight'"
                      :rules="[
                        {
                          required: true,
                          message: '总重量必填',
                          trigger: 'blur',
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (value < 0) {
                              callback(new error('总重量为正数'))
                            } else {
                              callback()
                            }
                          },
                          message: '总重量为正数',
                          trigger: 'blur',
                        },
                        {
                          validator: (rule, value, callback) => {
                            value = value + ''
                            value = value.split('.')[0]
                            if (value && value.length > 10) {
                              callback(new error('最大10位'))
                            } else {
                              callback()
                            }
                          },
                          message: '最大10位',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="form.productArranges[index].grossWeight"
                        controls-position="right"
                        :controls="false"
                        placeholder="请输入"
                        :precision="2"
                      ></el-input-number>
                    </el-form-item>
                  </td>
                  <!-- 总体积 -->
                  <td>
                    <el-form-item
                      :prop="'productArranges.' + index + '.volume'"
                      :rules="[
                        {
                          required: true,
                          message: '总体积必填',
                          trigger: 'blur',
                        },
                        {
                          validator: (rule, value, callback) => {
                            if (value < 0) {
                              callback(new error('总体积为正数'))
                            } else {
                              callback()
                            }
                          },
                          message: '总体积为正数',
                          trigger: 'blur',
                        },
                        {
                          validator: (rule, value, callback) => {
                            value = value + ''
                            value = value.split('.')[0]
                            if (value && value.length > 10) {
                              callback(new error('最大10位'))
                            } else {
                              callback()
                            }
                          },
                          message: '最大10位',
                          trigger: 'blur',
                        },
                      ]"
                    >
                      <el-input-number
                        v-model="form.productArranges[index].volume"
                        controls-position="right"
                        :controls="false"
                        placeholder="请输入"
                        :precision="2"
                      ></el-input-number>
                    </el-form-item>
                  </td>

                  <!-- 申报单价 -->
                  <td>
                    <el-input-number
                      v-model="item.declaredUnitPrice"
                      controls-position="right"
                      :controls="false"
                      :precision="3"
                    ></el-input-number>
                  </td>
                  <td>
                    <span class="link" @click="setMark(item)">点击查看</span>
                  </td>
                  <td>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="删除"
                      placement="top-start"
                    >
                      <el-button
                        type="danger"
                        plain
                        icon="el-icon-delete-solid"
                        circle
                        size="mini"
                        @click="beforeDelete(index)"
                      ></el-button>
                    </el-tooltip>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr class="empty">
                  <td colspan="99">暂无数据</td>
                </tr>
              </tbody>
            </table>
          </div>

          <h4>内部备注</h4>
          <el-input
            disabled
            type="textarea"
            v-model="form.insideRemarks"
            maxlength="500"
            show-word-limit
          ></el-input>
          <h4>附件</h4>
          <ErpUpload
            ref="ErpUpload"
            :uploadParams="uploadParams"
            @uploadList="uploadList"
            @initUpload="initUpload"
            style="margin-bottom: 40px; background: #e4e7ed; padding: 15px"
          ></ErpUpload>
        </el-form>
      </div>
    </el-row>
    <el-row style="text-align: center; position: sticky; bottom: 0">
      <el-button type="primary" @click="confirm">确认</el-button>
      <el-button @click="$router.back()">取消</el-button>
    </el-row>

    <!-- 唛头弹窗 -->
    <el-dialog
      :visible.sync="showMark"
      title="唛头"
      width="700px"
      append-to-body
      :close-on-click-modal="false"
      modal-append-to-body
    >
      <el-form
        ref="markForm"
        :model="markForm"
        :rules="markFormRules"
        label-position="left"
        label-width="50px"
      >
        <el-form-item label="主唛" prop="mainLabel">
          <el-input
            type="textarea"
            v-model="markForm.mainLabel"
            maxlength="500"
            :rows="8"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="侧唛" prop="sideMark">
          <el-input
            type="textarea"
            v-model="markForm.sideMark"
            maxlength="500"
            :rows="8"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item style="text-align: center">
          <el-button type="primary" @click="confirmMark">确认</el-button>
          <el-button @click="closeMarkDialog">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 人员选择 -->
    <UserChoose ref="UserChoose" @choose-user="chooseUser"></UserChoose>

    <!-- 图片预览 -->
    <el-image-viewer
      v-if="showViewer"
      :on-close="closeViewer"
      :url-list="imgUrl"
    />
    <!-- 入库批次选择 -->
    <LotBatchSel ref="lotBatchSel" @lotlist-set="setLocList" msg="出运" />

    <!-- 查看邮件模版示例 -->
    <EmailTemplate
      ref="EmailTemplate"
      :transportWay="
        form.shippingMethod ? form.shippingMethod.toUpperCase() : ''
      "
    ></EmailTemplate>

    <!-- 修改是否退税提示弹框 -->
    <el-dialog
      :visible.sync="drawbackVisible"
      width="450px"
      :close-on-click-modal="false"
      :show-close="false"
      custom-class="drawbackDialog"
    >
      <div class="content">
        <i class="el-icon-warning" />
        <div class="text">
          <p>
            你已修改「是否退税」选项，与采购订单上的此选项不一致，是否确认修改？
          </p>
          <p>建议你与采购人员进行核实，确认此产品是否退税。</p>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleDrawbackCancle(1)">
          取 消
        </el-button>
        <el-button @click="handleDrawbackConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    orderAddressProduct,
    storageFindWarehouseList2,
    getStockCostConfigureVO,
  } from '@/api/order'
  import { OrderSettingInteractor } from '@/core/interactors/order-setting'

  import {
    transpotwayList,
    insertWtd,
    tradeList,
    arrangeShipQuery,
    getShipmentPlanbatchDetail,
  } from '@/api/shipment/shipment-plan'
  import { lotRecommendArrange } from '@/api/stock-center/shipment'

  import { mapGetters, mapActions } from 'vuex'
  import UserChoose from '@/components/userChoose/index'
  import PackingSize from './components/packing-size'
  import LotDetail from './components/lot-detail'

  import Utils from '@/utils/utils'
  import { mixin } from './helper/mixin'

  import LotBatchSel from '@/components/lot-batch-sel/index.vue'

  import { clerkSettingList } from '@/api/order-setting'
  import popoverShip from './components/popoverShip.vue'
  import EmailTemplate from '@/components/EmailTemplate/index'
  import { deepCopy, format } from 'kits'
  export default {
    name: 'new-shipment-sheet',
    components: {
      UserChoose,
      PackingSize,
      popoverShip,
      LotDetail,
      LotBatchSel,
      EmailTemplate,
    },
    mixins: [mixin],
    data() {
      return {
        error: Error,
        orderInfo: {},
        currentPro: {},
        showMark: false,
        isDelivery: true, // 加工出库不需要同时出库
        markForm: {
          mainLabel: '',
          sideMark: '',
        },
        markFormRules: {
          mainLabel: [
            { required: true, message: '请输入主唛', trigger: 'blur' },
          ],
        },
        uploadParams: {
          size: 1024 * 1024 * 20, //单个文件上传大小
          amount: 9,
        },
        form: {
          trade: '',
          tradeId: '',
          portShipment: '', // 发货地点
          deliveryDate: '',
          shippingDate: '', // 装运日期
          remarks: '',
          domesticLogistics: '', //国内物流
          trackingNo: '', //快递单号
          advanceFreight: 0, //垫付运费
        },
        detailInfo: {}, // batchDetail接口返回详情
        pickerOptions: {}, // 客户交期配置项
        clerkList: [],
        rules: {
          piNumber: [{ required: true, message: '请输入订单编号' }],
          shippingMethod: [{ required: true, message: '请选择货运方式' }],
          portShipment: [
            { required: true, message: '请输入发货地点', trigger: 'blur' },
          ],
          tradeId: [
            { required: true, message: '请选择贸易条款', trigger: 'change' },
          ],
          shippingMethodId: [
            {
              required: true,
              message: '请选择运输方式',
              trigger: ['change', 'blur'],
            },
          ],
          deliveryMode: [{ required: true, message: '请选择交货方式' }],
          deliveryAddress: [{ required: true, message: '请选择物流仓库地址' }],
          arriveDate: [{ required: true, message: '请选择送达时间' }],
          deliveryDate: [{ required: true, message: '请选择客户交期' }],
          receivingMan: [{ required: true, message: '请输入收货人' }],
          receivingAddress: [{ required: true, message: '请输入收货地址' }],
          receivingCompany: [{ required: true, message: '请输入收货公司' }],
          receivingPhone: [{ required: true, message: '请输入联系电话' }],
          domesticLogistics: [{ required: true, message: '请选择国内物流' }],
          trackingNo: [{ required: true, message: '请输入快递单号' }],
          isChoose: false, //是否可以指定批次
        },
        transpotwayList: [], //货运方式
        portShipments: ['义乌', '苏州', '合肥'], //发货地点
        trades: [], //贸易条款
        deliveryModes: [
          //交货方式
          '自送',
          '上门取货',
          '门点做箱',
        ],
        deliveryAddresses: [], // 物流仓库地址
        storageList: [], // 出库仓库
        showViewer: false, //图片预览参数
        imgUrl: '', //图片预览url
        drawbacks: [
          {
            name: '是',
            code: '1',
          },
          {
            name: '否',
            code: '0',
          },
        ],
        files: '',
        tableKey: Math.random(),
        showRequired: false,
        domesticLogisticsList: [
          //国内物流下拉选项
          {
            code: 0,
            name: '快递',
          },
          {
            code: 1,
            name: '工厂送货',
          },
          {
            code: 2,
            name: '物流自提',
          },
        ],
        advanceFreightList: [
          //垫付运费下拉选项
          {
            code: 0,
            name: '是',
          },
          {
            code: 1,
            name: '否',
          },
        ],

        arrangeProductList: [],
        // 出运产品
        shipmentProduct: [],
        allSelect: false,
        shippingPlanOrderProductIds: [], // 从出运计划过来，并将选择的产品的id 带过来
        shippingPlanOrderProductSubIds: [], //从出运计划过来，并将选择的产品的子产品的id 带过来
        ingoreShippingMethodList: [
          'AIR-UPS',
          'AIR-FEDEX',
          'OCEAN-FAST SHIP-UPS',
          'OCEAN-FAST SHIP-FEDEX',
          'OCEAN-STANDARD SHIP-UPS',
          'OCEAN-STANDARD SHIP-FEDEX',
          'EXPRESS-DHL',
          'EXPRESS-UPS',
          'EXPRESS-FEDEX IP',
          'EXPRESS-FEDEX IE',
          'US-FEDEX',
          'US-UPS',
          'EXPRESS-SHUNFENG',
        ],

        canSelectAutoEmail: false,
      }
    },

    watch: {
      'form.shippingMethodId': {
        handler: function (newval, oldval) {
          let shippingMethod = this.transpotwayList.find((i) => i.id === newval)
          if (shippingMethod) {
            this.form.shippingMethod = shippingMethod.transportWayCn
            this.form.shippingMethodType = shippingMethod.transportType
          } else {
            this.form.shippingMethodId = ''
          }
          if (oldval) {
            this.form.tradeId = ''
          }
        },
      },
      'form.tradeId': function (newval) {
        if (!this.trades.length || !newval) return
        let trade = this.trades.find((i) => i.amountId === newval)
        if (trade) {
          this.form.trade = trade.amountName
        }
      },
    },

    computed: {
      ...mapGetters({
        userInfo: 'user/userInfo',
        permissions: 'user/permissions',
      }),
      clerkName() {
        if (this.form.shippingMethodId) {
          let shippingMethod = this.transpotwayList.find(
            (i) => i.id === this.form.shippingMethodId
          )
          if (!shippingMethod) return ''
          const { transportType } = shippingMethod
          const clerkSetting = this.clerkList.find(
            (i) => i.transportType == transportType
          )

          if (!clerkSetting) {
            return ''
          } else {
            return `${clerkSetting.clerkNameCn} ${clerkSetting.clerkNameEn}`
          }
        }
      },
      clerkId() {
        let shippingMethod = this.transpotwayList.find(
          (i) => i.id === this.form.shippingMethodId
        )
        if (!shippingMethod) return ''
        const { transportType } = shippingMethod
        const clerkSetting = this.clerkList.find(
          (i) => i.transportType == transportType
        )
        if (!clerkSetting) {
          return ''
        } else {
          return clerkSetting.clerkId
        }
      },
    },
    created() {
      this.getDict()
      this.shippingPlanOrderProductIds =
        this.$route.query.shippingPlanOrderProductIds?.split(',')
      this.shippingPlanOrderProductSubIds =
        this.$route.query.shippingPlanOrderProductSubIds?.split(',')
      clerkSettingList().then((res) => {
        if (res && res.code === '000000') {
          this.clerkList = res.data || []
        }
        // 初始化数据
      })

      this.getTranspotwayList()
    },
    methods: {
      createInit() {
        // 获取产品列表
        let shippingPlanOrderId = this.$route.query.shippingPlanOrderId

        let params = {
          shippingPlanOrderId: shippingPlanOrderId,
          shippingPlanOrderProductIds: this.shippingPlanOrderProductIds,
          shippingPlanOrderProductSubIds: this.shippingPlanOrderProductSubIds,
        }
        getShipmentPlanbatchDetail([params])
          .then((res) => {
            if (res?.code === '000000') {
              console.log('batchDetail', deepCopy(res.data))
              this.detailInfo = res.data
              let { deadLineDate } = res.data
              if (deadLineDate) {
                this.pickerOptions = {
                  // 客户交期不能晚于deadLineDate
                  disabledDate(time) {
                    return time.getTime() > new Date(deadLineDate).getTime()
                  },
                }
              }
              this.arrangeProductList = res?.data?.productDetails?.map(
                (item) => {
                  return {
                    ...item,
                    arrangeChildProducts: item.childProduct,
                    shippedNum: item.unShippedNum,
                    drawback: '',
                  }
                }
              )
              this.form.productArranges = [...this.arrangeProductList]
            }
          })
          .then(() => {
            this.$_initData()
          })
      },
      //查看物流示例
      handleView() {
        this.$refs.EmailTemplate.dialogFormVisible = true
      },
      whChange(index, val) {
        // 清空仓库时，重置买方、是否退税，是否退税为禁用状态
        if (!val) {
          this.setEmptyByIndex(index, 1)
        }
        this.lotRecommendArrange(index, 'whchange')
      },
      async shippedNumChange(index) {
        // 判断能否大于出运数量
        const { value } =
          await OrderSettingInteractor.getOutboundQuantityConfig()
        if (!value) {
          const currentPro = this.form.productArranges[index]
          const { unShippedNum, shippedNum } = currentPro
          if (shippedNum > unShippedNum) {
            this.$message.warning('出运数量不能大于待出运数量')
            currentPro.shippedNum = unShippedNum
            this.tableKey = Math.random()
          }
        }
        this.lotRecommendArrange(index)
      },
      // 获取推荐批次
      getRecommandLots(index) {
        const cr = this.form.productArranges[index]
        if (
          cr.shippingOrderProductLotDTOList &&
          cr.shippingOrderProductLotDTOList.length > 0
        ) {
          return cr.shippingOrderProductLotDTOList
        }
        const {
          warehouseId,
          warehouseInLotArrangeVOList, //推荐的批次
        } = cr
        if (!warehouseInLotArrangeVOList) {
          return []
        }
        return warehouseInLotArrangeVOList.filter(
          (i) => i.warehouseId == warehouseId
        )
      },

      // 获取推荐批次
      async lotRecommendArrange(index, isWhchange) {
        const pro = this.form.productArranges[index]
        const {
          shippedNum: num,
          sku: skuId,
          warehouseId,
          mainFlag,
          shippingPlanOrderProductId,
          orderProductUniqueId,
        } = pro
        pro.shippingOrderProductLotDTOList = []
        if (isWhchange && warehouseId) {
          const res = await getStockCostConfigureVO({ warehouseId })
          if (res && res.code === '000000') {
            this.$set(
              pro,
              'isChoose',
              res.data?.warehouseInBatch === 1 ? true : false
            )

            pro.warehouseOutType = res.data?.warehouseOutType
          }
        }
        if (pro.warehouseOutType == 1 || pro.hasLock) {
          // 清空批次信息
          if (num && warehouseId) {
            lotRecommendArrange({
              num,
              skuId,
              tenantId: this.userInfo.tenantId,
              salesOrderNumber: this.form.piNumber,
              warehouseId,
              mainFlag,
              shippingPlanOrderProductId,
              orderProductUniqueId,
              recommendLotDTOs: this.getRecommendLotDTOs(
                index,
                this.form.productArranges
              ),
            }).then((res) => {
              if (res && res.code === '000000') {
                pro.shippingOrderProductLotDTOList = res.data.map((item) => {
                  item.lotNumber = item.num
                  return item
                })
                // 仓库change时或出运数量变化引起批次变化时，实时更新买方和是否退税
                this.shippingOrderPurchase(
                  pro.shippingOrderProductLotDTOList,
                  index,
                  1
                )
              }
            })
          }
        }
      },
      // 设置入库批次
      setLocList(list) {
        const { currentLotIndex: index } = this
        const currentLotList = list.map((item) => {
          item.lockLotFlag = 'lockLotFlag' in item ? item.lockLotFlag : 0
          item.num = item.lotNumber
          return item
        })
        this.form.productArranges[index].shippingOrderProductLotDTOList =
          currentLotList
        this.tableKey = Math.random()
        // 如果批次发生改变，点击提交时需要调一次接口重新回显买方和是否退税
        this.shippingOrderPurchase(currentLotList, index, 1)
      },
      // 选择入库批次和数量
      selLot(index) {
        this.currentLotIndex = index
        // 判断是否选择入库仓库
        const cr = this.form.productArranges[index]
        if (cr.alreadyStockIn == '1') {
          return this.$message.warning('无法更改')
        }
        if (!cr.warehouseId) {
          return this.$message.warning('请选择出库仓库')
        }

        // 添加orderProductUniqueIds
        this.$refs.lotBatchSel.show({
          list: this.getRecommandLots(index),
          sku: cr.sku,
          orderProductUniqueId: cr.orderProductUniqueId,
          productName: cr.productCn,
          warehouseId: cr.warehouseId,
          applyNum: cr.shippedNum,
          orderCode: this.form.piNumber,
        })
        // 点击编辑icon时，存储当前的批次
        this.prevShippingOrderProductLotDTOList = deepCopy(
          cr.shippingOrderProductLotDTOList
        )
      },
      onShippingMethodChange(val) {
        if (val) {
          let shippingMethod = this.transpotwayList.find((i) => i.id === val)
          if (!shippingMethod) {
            return ''
          }
          const { transportType } = shippingMethod
          const clerkSetting = this.clerkList.find(
            (i) => i.transportType == transportType
          )
          if (!clerkSetting) {
            this.form.clerkName = ''
            this.form.clerkId = ''
          } else {
            this.form.clerkName = `${clerkSetting.clerkNameCn} ${clerkSetting.clerkNameEn}`
            this.form.clerkId = clerkSetting.clerkId
          }
        } else {
          this.form.clerkName = ''
          this.form.clerkId = ''
        }
      },

      // 设置产品装箱尺寸
      setSizes(index, sizes) {
        this.form.productArranges[index].orderProductBoxDTOS = sizes
        this.tableKey = Math.random()
      },
      // 展示或隐藏装箱尺寸编辑图标
      toggleEdit(index, flag) {
        const ref = index + 'packingSize'
        this.$refs[ref][0].toggleEditIcon(flag)
      },
      initUpload(data) {
        this.$refs.ErpUpload.initUpload(data)
      },
      uploadList(fileList) {
        this.files = JSON.stringify(fileList)
      },

      showProDetail(proId) {
        if (proId.sku.indexOf('FL') > -1) return
        let routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: {
            proId: proId.productId,
            editProduct: 1,
          },
        })
        window.open(routeData.href, '_blank')
      },
      showUserTree() {
        let roleId = this.form.clerkId
        this.$refs.UserChoose.showAddEdit(
          'newShipping',
          roleId
            ? [
                {
                  id: roleId,
                  name: this.form.clerkName,
                },
              ]
            : '',
          true
        )
      },
      // 获取字典项
      getDict() {
        // 获取出库仓库下拉框
        storageFindWarehouseList2({
          hasAuth: 1,
          hasCloudWarehouse: 1,
        }).then((res) => {
          this.storageList = res?.data
        })
      },

      querySearchAsync(queryString, cb) {
        if (this.addressList && this.addressList.length) {
          let restaurants = this.addressList.map((item) => {
            return {
              ...item,
              value: item.address,
            }
          })
          cb(restaurants)
        }
      },

      // 选择地址带入其他信息
      handleSelect(item) {
        let self = this
        self.form.receivingAddress = item.address
        self.form.receivingCompany = item.companyName
        self.form.receivingMan = item.consigneeName
        self.form.receivingPhone = item.consigneePhone
        self.getProductList(item.addressId)
      },

      // 获取产品列表
      getProductList(id) {
        orderAddressProduct({ addressId: id }).then((res) => {
          if (res.data.length) {
            this.form.productArranges = []
            res.data.forEach((item) => {
              item.drawback = ''
              if (
                (item.reamount > 0 &&
                  item.productStatus != 3 &&
                  item.productStatus != 4 &&
                  item.alignOrder == 1) ||
                (item.reamount > 0 && item.standardCustomized == '订制品')
              ) {
                this.form.productArranges.push({
                  ...item,
                  productType: item.standardCustomized,
                  productName: item.productEn,
                  shippedNum: item.reamount,
                  drawback: '', // 是否退税默认为否
                  caseNum: item.quantTotle
                    ? Math.ceil(item.quantTotle / item.numberPerBox)
                    : '', // 总箱数 = 出运数量 ÷ 装箱数量 （向上取整）
                  volume: item.quantTotle
                    ? parseFloat(
                        item.boxVolumeM * (item.quantTotle / item.numberPerBox)
                      ).toFixed(3)
                    : '', // 总体积 = 装箱体积*总装箱数量
                  grossWeight: item.quantTotle
                    ? parseFloat(
                        (item.quantTotle / item.numberPerBox) *
                          item.boxWeightKgs
                      ).toFixed(3)
                    : '', // 总重量 = 总箱数 * 毛重
                })
              }
            })
            if (!this.form.productArranges.length) {
              this.$message.warning('该地址下暂无产品！')
            }
          } else {
            this.$message.warning('该地址下暂无产品！')
          }
        })
      },

      showUserDialog() {
        let { businessName, businessId } = this.form,
          selectUsers = []
        if (businessName) {
          selectUsers = [
            {
              name: businessName,
              id: businessId,
            },
          ]
        }
        this.$refs['UserChoose'].showAddEdit('newShipping', selectUsers)
      },
      chooseUser(users) {
        if (!users || users.length === 0) {
          return
        }
        this.form.clerkId = users[0].id
        this.form.clerkName = users[0].name
      },
      beforeDelete(index) {
        Utils.baseConfirm(this).then(() => {
          this.deletePro(index)
        })
      },
      deletePro(index) {
        this.form.productArranges.splice(index, 1)
      },

      confirmMark() {
        this.$refs.markForm.validate((res) => {
          if (res) {
            const { mainLabel, sideMark } = this.markForm
            this.currentPro.mainLabel = mainLabel
            this.currentPro.sideMark = sideMark
            this.closeMarkDialog()
          }
        })
      },
      closeMarkDialog() {
        this.showMark = false
      },
      setMark(item) {
        this.currentPro = item
        this.showMark = true
        const { mainLabel, sideMark } = item
        this.markForm.mainLabel = mainLabel
        this.markForm.sideMark = sideMark
      },
      $_checkPackingSize() {
        let pros = this.form.productArranges
        this.showRequired = pros.some((item) => {
          if (
            !item.orderProductBoxDTOS ||
            item.orderProductBoxDTOS.length === 0
          ) {
            return true
          } else {
            return false
          }
        })
        this.tableKey = Math.random()
        return this.showRequired
      },
      // 数量校验
      checkLotCounts() {
        const tableData = this.form.productArranges
        for (let i = 0; i < tableData.length; i++) {
          const item = tableData[i]
          if (!item.warehouseId) {
            continue
          }
          if (item.shippingOrderProductLotDTOList?.length === 0) {
            this.$message.warning(`请选择${item.productCn}入库批次`)
            return false
          }
          const totalLotNums = item.shippingOrderProductLotDTOList.reduce(
            (prev, next) => {
              prev += next.lotNumber
              return prev
            },
            0
          )
          if (totalLotNums !== item.shippedNum) {
            this.$message.warning(
              `${item.productCn}入库批次（数量）和出运数量不一致`
            )
            return false
          }
        }
        return true
      },

      // 确认新建
      confirm() {
        if (this.$_checkPackingSize()) return
        if (!this.checkLotCounts()) return
        // 延时解决tableKey导致校验信息不展示的问题
        setTimeout(() => {
          this.$refs.ruleForm.validate((res) => {
            if (!res) {
              return
            }
            // 列非空校验
            if (
              !this.columnCheck(this.form.productArranges, {
                grossWeight: '总重量不能都为0',
                volume: '总体积不能都为0',
              })
            ) {
              return
            }

            // 产品唛头校验
            let products = this.form.productArranges.map((pro) => {
              return pro
            })
            let pro2 = products.find((pro) => !pro.mainLabel)
            if (pro2) {
              return this.$message.warning(`产品${pro2.sku}的主唛不能为空`)
            }

            if (
              this.canSelectAutoEmail &&
              this.form.shippingMethodId &&
              this.ingoreShippingMethodList.includes(
                this.form.shippingMethod.toUpperCase()
              )
            ) {
              this.form.emailAutoFlag = 1
            } else {
              this.form.emailAutoFlag = 0
            }
            let requestData = JSON.parse(JSON.stringify(this.form))
            if (this.clerkName) {
              this.form.clerkName = this.clerkName.trim()
            }

            requestData.clerkName = this.form.clerkName
            requestData.clerkId = this.clerkId
            requestData.receivingAddress = requestData.destination
            // 产品列表字段修改
            requestData.shippingOrderProductDTOS =
              requestData.productArranges.map((pro) => {
                if (
                  pro.shippingPlanOrderProductArrangeStockVOS &&
                  pro.shippingPlanOrderProductArrangeStockVOS.length > 0
                ) {
                  pro.warehouseOutFlag = 1
                } else {
                  pro.warehouseOutFlag = 0
                }
                return pro
              })
            delete requestData.productArranges
            requestData.accessoryUrl = this.files
            requestData.shippingOrderProductDTOS =
              requestData.shippingOrderProductDTOS.map((pro) => {
                if (pro.shippingPlanOrderProductArrangeStockVOS) {
                  pro.stockSaveDTOS =
                    pro.shippingPlanOrderProductArrangeStockVOS
                  delete pro.shippingPlanOrderProductArrangeStockVOS
                } else {
                  if (pro.warehouseId) {
                    let stockSaveDTOS = {}
                    stockSaveDTOS.warehouseId = pro.warehouseId
                    stockSaveDTOS.warehouseName =
                      pro.shippingPlanOrderProductStockVOS?.find(
                        (w) => w.storageId == pro.warehouseId
                      )?.storageName
                    stockSaveDTOS.warehouseOutNum = pro.shippedNum
                    pro.stockSaveDTOS = [stockSaveDTOS]
                  } else {
                    pro.stockSaveDTOS = []
                  }
                }
                pro.childOrderProductList = pro.arrangeChildProducts
                pro.parentProduct =
                  pro.parentProduct?.length > 0 ? pro.parentProduct[0] : {}
                delete pro.arrangeChildProducts
                delete pro.parentList
                return {
                  ...pro,
                  alreadyStockIn: pro.alreadyStockIn ? pro.alreadyStockIn : 0,
                  isPrint: pro.standardCustomized === '标准品' ? 1 : 0,
                  standardCustomized:
                    pro.standardCustomized === '标准品' ||
                    pro.standardCustomized == '1'
                      ? 1
                      : pro.standardCustomized === '订制品' ||
                        pro.standardCustomized == '2'
                      ? 2
                      : '',
                }
                // 处理stockSaveDTOS
              })
            requestData.shippingOrderProductDTOS.forEach((item) => {
              delete item.childProduct
            })
            // 处理子产品添加productSubId
            for (let k of this.arrangeProductList) {
              for (let j of requestData.shippingOrderProductDTOS) {
                if (
                  k.shippingPlanOrderProductId === j.shippingPlanOrderProductId
                ) {
                  j.shippingPlanOrderProductSubId = k.productSubId
                }
              }
            }
            const { deliveryDate, shippingDate } = requestData
            if (shippingDate > deliveryDate) {
              this.$message.warning('装运日期不能晚于客户交期')
              return (this.loading = false)
            }
            requestData.firmFlag = this.detailInfo.firmFlag || 0
            insertWtd(requestData).then((res) => {
              if (res && res.code === '600039') {
                //生成出运委托单时，判断出库单状态，如果被驳回，需弹框提示并刷新页面
                this.$message.warning(res.desc)
                return this.$router.push({
                  path: '/order/shipmentManage/shipment-plan',
                })
              }
              if (res && res.code === '600040') {
                //生成出运委托单时，判断出库单状态，如果被驳回，需弹框提示并刷新页面
                this.$message.warning(res.desc)
                return this.$Bus.$emit('reload-router-view')
              }
              if (res && res.code === '000000') {
                this.$message.success('操作成功')
                localStorage.removeItem('planDetailTable')
                this.$router.push({
                  path: '/order/shipmentManage/shipmentManage',
                })
              }
            })
          })
        }, 400)
      },
      // 出库出库仓库
      $_handleSelectedWh(tableData) {
        return tableData.map((td) => {
          // td.hasNotCompleteInStock = 1
          let lockStock = td.shippingPlanOrderProductStockVOS.find(
            (item) => item.lockLotFlag === 1
          )
          if (lockStock) {
            td.warehouseId = lockStock.storageId
            td.hasLock = true
          }
          // }
          // 组合拼装请求时装箱尺寸参数orderProductBoxDTOS
          const { boxLengthCm, boxWidthCm, boxHeightCm } = td
          td.orderProductBoxDTOS = [
            {
              boxLengthCm,
              boxWidthCm,
              boxHeightCm,
            },
          ]
          return td
        })
      },
      $_initData() {
        // 获取计划单信息
        if (this.$route.query.shippingPlanOrderId) {
          const { shippingPlanOrderId } = this.$route.query
          this.canSelectAutoEmail = false
          arrangeShipQuery({
            shippingPlanOrderId: shippingPlanOrderId,
            arrangeProductList: this.arrangeProductList,
          }).then((res) => {
            this.canSelectAutoEmail = res?.data?.canSelectAutoEmail
            if (res?.data?.productArranges?.length > 0) {
              res.data.productArranges.map((item) => {
                for (let k of this.form?.productArranges) {
                  if (!item.productImg && item.productId === k['productId'])
                    item.productImg = k['productImg']
                }
              })
              this.form = {
                ...this.form,
                ...res.data,
                creatorName: JSON.parse(localStorage.getItem('setUserInfo'))
                  .userName,
                shippingMethodId: this.detailInfo.shippingMethodId, // 运输方式
                deliveryDate: this.detailInfo.deliveryDate, // 客户交期
                shippingDate: format(new Date()), // 装运日期默认为当天
              }

              // 将batchDetail接口的数据赋值到productArranges中---这里是初始化最后一次赋值
              if (this.detailInfo?.productDetails?.length) {
                this.form.productArranges.forEach((item) => {
                  this.detailInfo.productDetails.forEach((detail) => {
                    if (
                      item.shippingPlanOrderProductId ===
                      detail.shippingPlanOrderProductId
                    ) {
                      item.shippedNum = detail.shippingNum
                    }
                  })
                })
              }
              this.form.productArranges = this.$_handleSelectedWh(
                this.form.productArranges
              )

              this.form.productArranges = this.form.productArranges.map(
                (item, index) => {
                  if (item.alreadyStockIn === '1') {
                    item.shippingOrderProductLotDTOList =
                      item.warehouseInLotArrangeVOList.map((item) => {
                        item.lotNumber = item.num
                        return item
                      }) || []
                    console.log('初始化1？')
                    // 初始化时，入库批次重置时，需要调接口回显买方和是否退税
                    this.shippingOrderPurchase(
                      item.shippingOrderProductLotDTOList,
                      index,
                      1
                    )
                  }
                  return {
                    ...item,
                    selected: false,
                    parentProduct: item.parentProduct
                      ? [item.parentProduct]
                      : [],
                    shippingOrderProductLotDTOList:
                      item.shippingOrderProductLotDTOList
                        ? item.shippingOrderProductLotDTOList
                        : [],
                  }
                }
              )
              setTimeout(() => {
                this.form.productArranges.forEach(async (item, index) => {
                  if ([1, 2].includes(item.hasNotCompleteInStock)) {
                    item.warehouseId = ''
                  }
                  if (item.warehouseId) {
                    const {
                      shippedNum: num,
                      sku: skuId,
                      warehouseId,
                      hasLock,
                      mainFlag,
                      shippingPlanOrderProductId,
                      orderProductUniqueId,
                    } = item

                    const res = await getStockCostConfigureVO({
                      warehouseId: warehouseId,
                    })
                    if (res && res.code === '000000') {
                      this.$set(
                        item,
                        'isChoose',
                        res.data?.warehouseInBatch === 1 ? true : false
                      )
                      item.warehouseOutType = res.data?.warehouseOutType
                    }
                    if (item.alreadyStockIn == 1 || hasLock) {
                      item.shippingOrderProductLotDTOList =
                        item.warehouseInLotArrangeVOList.map((item) => {
                          item.lotNumber = item.num
                          return item
                        })
                      // 初始化时，入库批次重置时，需要调接口回显买方和是否退税
                      this.shippingOrderPurchase(
                        item.shippingOrderProductLotDTOList,
                        index,
                        1
                      )
                      console.log('初始化2？')
                    } else {
                      if (item.warehouseOutType == 1) {
                        if (num && warehouseId) {
                          const res = lotRecommendArrange({
                            num,
                            skuId,
                            tenantId: this.userInfo.tenantId,
                            salesOrderNumber: this.form.piNumber,
                            warehouseId,
                            mainFlag,
                            shippingPlanOrderProductId,
                            orderProductUniqueId,
                          }).then((res) => {
                            if (res && res.code === '000000') {
                              item.shippingOrderProductLotDTOList =
                                res.data.map((subItem) => {
                                  subItem.lotNumber = subItem.num
                                  return subItem
                                })
                              this.tableKey = Math.random() * Math.random()
                              // this.tableKey = Math.random()
                              console.log('初始化3？')
                              // 初始化时，入库批次重置时，需要调接口回显买方和是否退税
                              this.shippingOrderPurchase(
                                item.shippingOrderProductLotDTOList,
                                index,
                                1
                              )
                            }
                          })
                        }
                      }
                    }
                  } else {
                    // 初始化时没有出库单号，买方和是否退税重置，是否退税为禁用状态
                    // alreadyStockIn：1=有出库单 0=无出库单   如果有出库单，不需要重置买方和是否退税
                    if (item.alreadyStockIn === '0')
                      this.setEmptyByIndex(index, 1)
                  }
                  // 处理processOrReturn
                  if (item.shippingOrderProductLotDTOList?.length > 0) {
                    if (
                      item.shippingOrderProductLotDTOList.some(
                        (s) => s.processOrReturn
                      )
                    ) {
                      this.$set(item, 'stockDisable', true)
                    }
                  }
                })
              }, 20)
              this.tableKey = Math.random() * Math.random()
              // 初始化渲染单证员
              this.onShippingMethodChange(res.data.shippingMethodId)
            }
          })
          // 贸易条款tradeList
          tradeList().then((res) => {
            if (res && res.code === '000000') {
              // this.trades = res.data
              this.initTrades = res.data
              if (this.form.shippingMethodType === 3) {
                this.trades = this.initTrades.filter(
                  (item) => item.amountName === 'DDP'
                )
              } else {
                this.trades = this.initTrades
              }
            }
          })
        }
      },

      // 获取运输方式下拉
      getTranspotwayList() {
        transpotwayList().then((res) => {
          if (res && res.code === '000000') {
            this.transpotwayList = res.data.map((i) => {
              i.id = i.id + ''
              return i
            })
            this.createInit() // 在获取到运输方式下拉后，再进行初始化数据
          }
        })
      },

      // 大图预览
      handlePreview(item) {
        this.imgUrl = item.split(',')

        this.showViewer = true
      },
      closeViewer() {
        this.showViewer = false
      },

      // 返回
      goBack() {
        this.$router.push('/order/shipmentManage/shipmentManage')
      },

      // 计算总箱数
      calculateCaseNum(i) {
        let self = this
        let list = self.form.productArranges
        list[i].caseNum = Math.ceil(list[i].shippedNum / list[i].numberPerBox)
      },

      // 计算总体积
      calculateVolume(i) {
        let self = this
        let list = self.form.productArranges
        list[i].volume = parseFloat(
          list[i].boxVolumeM * list[i].caseNum
        ).toFixed(3)
      },

      // 计算总重量
      calculateWeight(i) {
        let self = this
        let list = self.form.productArranges
        list[i].grossWeight = parseFloat(
          list[i].caseNum * list[i].boxWeightKgs
        ).toFixed(3)
      },

      // 输入总箱数
      inputCaseNum(i) {
        let self = this
        let list = self.form.productArranges
        list[i].volume = parseFloat(
          list[i].boxVolumeM * list[i].caseNum
        ).toFixed(3)
        list[i].grossWeight = parseFloat(
          list[i].caseNum * list[i].boxWeightKgs
        ).toFixed(3)
      },

      // 输入总体积
      inputVolume(i) {
        let self = this
        let list = self.form.productArranges
        list[i].caseNum = Math.ceil(list[i].volume / list[i].boxVolumeM)
        list[i].grossWeight =
          Math.ceil(list[i].volume / list[i].boxVolumeM) * list[i].boxWeightKgs
      },

      // 输入总重量
      inputWeight(i) {
        let self = this
        let list = self.form.productArranges
        list[i].caseNum = Math.ceil(list[i].grossWeight / list[i].boxWeightKgs)
        list[i].volume = parseFloat(
          list[i].caseNum * list[i].boxVolumeM
        ).toFixed(3)
      },

      // 计算总箱数，总体积， 总重量
      calculateNumber(i) {
        let self = this
        let list = self.form.productArranges
        // 计算总箱数
        list[i].caseNum = Math.ceil(list[i].shippedNum / list[i].numberPerBox)
        // 计算总体积
        list[i].volume = parseFloat(
          list[i].boxVolumeM * list[i].caseNum
        ).toFixed(3)
        // 计算总重量
        list[i].grossWeight = parseFloat(
          list[i].caseNum * list[i].boxWeightKgs
        ).toFixed(3)
      },

      tdSelect() {
        let state = this.form.productArranges.every((item) => {
          return item.selected
        })
        if (state) {
          this.allSelect = true
        } else {
          this.allSelect = false
        }
      },
      thSelect(val) {
        this.form.productArranges.forEach((item) => {
          this.$set(item, 'selected', val)
        })
      },

      // 设置装运日期
      handleSetShippingDate(day) {
        this.form.shippingDate = format(new Date(), day)
      },

      // 当入库批次发生变化时执行
      // lotListChange(list = [], index) {
      //   this.shippingOrderPurchase(list, index)
      // },
    },
  }
</script>

<style scoped lang="scss">
  .order-content {
    padding: 0 20px 90px;

    h4 {
      font-family: 'PingFang Bold';
      font-size: 14px;
      margin: 20px 0 10px 0;
    }

    .el-input,
    .el-select {
      width: 100%;
    }

    .el-form-item {
      margin-bottom: 0;
    }
  }

  td .link {
    cursor: pointer;
    color: #1890ff;
    cursor: pointer;
    text-decoration: underline;
  }

  .custom-table tbody td {
    padding: 18px 10px;
    position: relative;
  }

  // ::v-deep .el-input-number--small {
  //   width: 100px !important;
  // }
  // ::v-deep .el-input-number--small .el-input__inner {
  //   padding: 0 10px !important;
  // }
  // ::v-deep .el-input-number--small .el-input-number__increase,
  // ::v-deep .el-input-number--small .el-input-number__decrease {
  //   display: none !important;
  // }
  // .print-image {
  //   display: flex;
  //   // flex-direction: column;
  //   align-items: center;
  // }
  .fileAnnex-span {
    display: inline-block;
    width: 15px;
    height: 15px;
    line-height: 15px;
    text-align: center;
    background: #9e9e9e;
    color: #fff;
    border-radius: 50%;
    margin-left: 5px;
    font-size: 12px;
  }

  .head-check {
    min-width: auto;
  }

  ::v-deep .product-img-box {
    .el-tag {
      position: absolute;
      top: 4px;
      right: 4px;
      z-index: 2;

      &.el-tag-other {
        background-color: #fff7e8;
        border-color: #ffefd1;
        color: #ffae18;
      }
    }

    .imgs-sty {
      height: auto;
    }
  }

  .selectFlex {
    /deep/ .el-form-item__content {
      display: flex;
      align-items: center;
    }
  }

  .custom-container {
    // 是否退税确认弹框
    /deep/ .drawbackDialog {
      .el-dialog__body {
        padding-top: 0;

        .content {
          display: flex;

          i {
            color: #ffba00;
            font-size: 24px;
            margin-right: 16px;
          }

          p:first-of-type {
            font-weight: bold;
          }
        }
      }
    }
  }
</style>
